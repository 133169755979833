<template>
  <div style="width: 320px;padding: 10px;" @click="e => e.stopPropagation()">
    <el-table :data="list">
      <el-table-column width="100" align="center" property="cnName" label="负责人" />
      <el-table-column width="100" align="center" property="roleName" label="角色名称">
        <template #default="scope">
          <img
            v-if="scope?.row?.isLeader == 1"
            :src="guanPng"
            alt=""
            style="width: 15px;height: 15px;margin-right: 5px;vertical-align: middle;"
          />
          <span style=" display: inline; vertical-align: middle;">{{ scope?.row?.roleName }}</span>
        </template>
      </el-table-column>
      <el-table-column width="100" align="center" property="deptName" label="部门名称" />
    </el-table>
  </div>
</template>

<script setup>
import { defineProps } from 'vue'
import { ElTable, ElTableColumn } from 'element-plus'

import guanPng from '@/assets/tu/guan.png'

defineProps({
  list: {
    type: Array,
    default: () => []
  }
})
</script>

<style lang="scss" scoped>

</style>
