<template>
  <div ref="containerRef" class="dynamic-popover-container">
    <div class="show-content" @click.stop="mountSlot">
      <slot name="reference"></slot>
    </div>
  </div>
</template>

<script setup lang="jsx">
import { ref, createApp, useSlots, onMounted, onUnmounted } from 'vue'
import { v4 as uuidv4 } from 'uuid'

const popoverContent = ref()
const mountBox = ref()
const id = uuidv4()
const containerRef = ref()
const slots = useSlots()

onMounted(() => {
  document.addEventListener('click', () => {
    if (window.popoverContentInstanceQzf) {
      window.popoverContentInstanceQzf.unmount()
      popoverContent.value = null
    }
  })
})

onUnmounted(() => {
  mountBox.value?.remove()
  mountBox.value = undefined
})

// 计算弹出的盒子的位置
function calculatePosition() {
  const boxRect = mountBox.value.getBoundingClientRect()
  const bodyRect = document.body.getBoundingClientRect()
  const containerRect = containerRef.value.getBoundingClientRect()
  if (bodyRect.bottom - containerRect.bottom > boxRect.height) {
    mountBox.value.style.top = `${containerRect.bottom + 10}px`
  } else {
    mountBox.value.style.top = `${containerRect.top - boxRect.height - 10}px`
  }
  if (bodyRect.right - containerRect.left > (boxRect.width / 2)) {
    mountBox.value.style.left = `${containerRect.left - boxRect.width / 2 + containerRect.width / 2}px`
  } else {
    mountBox.value.style.left = `unset`
    mountBox.value.style.right = `10px`
  }
}

// 挂载 slot
function mountSlot() {
  if (window.popoverContentInstanceQzf) {
    if (window.popoverContentInnerQzf.value !== popoverContent.value) {
      window.popoverContentInstanceQzf.unmount()
      window.popoverContentInnerQzf.value = null
    }
  }
  if (!popoverContent.value) {
    popoverContent.value = window.popoverContentInstanceQzf = createApp({
      render() {
        return <>
          { slots.default?.() }
        </>
      },
    })
    window.popoverContentInnerQzf = popoverContent
    createMountBox()
    popoverContent.value.mount(mountBox.value)
    calculatePosition()
  } else {
    popoverContentUnmount()
  }
}

function popoverContentUnmount () {
  popoverContent.value.unmount()
  popoverContent.value = null
}
function createMountBox() {
  let box = document.querySelector(`[id='${id}']`)
  if (!box) {
    box = document.createElement('div')
    box.id = id
    box.classList.add('dynamic-popover-box')
    document.body.appendChild(box)
  }
  mountBox.value = box
}
</script>

<style scoped lang="scss">
.dynamic-popover-container {
  width: 100%;
  height: 100%;
  .show-content {
    width: 100%;
    height: 100%;
  }
}
</style>
<style>
.dynamic-popover-box {
  position: fixed;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);
  z-index: 10000;
}
</style>