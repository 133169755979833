<template>
  <el-dialog :close-on-click-modal="false" destroy-on-close title="导入提示" v-model="dialogTableVisible" append-to-body>
    <div style="margin-bottom:10px;">
      <span style="color: #67c23a;margin-right:20px">导入成功条数:{{ callList.success_total }}</span>
      <span style="color:#F15A24;margin-right:20px">导入失败条数:{{ callList.fail_total }}</span>
      <span style="color:#F15A24" v-if="callList.fail_reason">导入失败原因:{{ callList.fail_reason }}</span>
    </div>
    <div style="height: 450px;overflow: auto;">
      <el-table :data="callList.fail_list" size="small" border>
        <el-table-column align="center" property="name" label="姓名"></el-table-column>
        <el-table-column align="center" property="remark" label="原因"></el-table-column>
      </el-table>
      <div v-if="callList.lz && callList.lz.length != 0">
        <div class="record">
          <div class="record_left">
            离职员工
          </div>
          <el-button type="primary" @click="visible = true" size="small">批量设置</el-button>
        </div>

        <el-table :data="callList.lz" size="small" border @selection-change="handleSelectionChange" >
          <el-table-column type="selection" align="center" width="40"/>
          <el-table-column property="name" label="姓名"></el-table-column>
          <el-table-column property="iDNo" label="身份证号"></el-table-column>
          <el-table-column property="tel" label="手机号"></el-table-column>
          <el-table-column label="是否雇员">
            <template #default="{ row }">
              <el-select v-model="row.isEmployee" filterable size="small">
                <el-option v-for="itemb in options" :key="itemb.value" :label="itemb.label" :value="itemb.value">
                </el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column property="remark" label="离职日期">
            <template #default="scope">
              <el-date-picker style="width: 100%" type="date" placeholder="选择离职日期" value-format="YYYY-MM-DD"
                v-model="scope.row.departureDate" size="small"></el-date-picker>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="dialogTableVisible = false" size="small">关闭</el-button>
        <el-button type="primary" @click="saveTimebatch()" size="small"
          v-if="callList.lz && callList.lz.length != 0">确定</el-button>
      </div>
    </template>
  </el-dialog>
  <el-dialog :close-on-click-modal="false" destroy-on-close title="批量设置" v-model="visible" width="450px" append-to-body>
    <el-form label-width="85" size="small">
      <el-form-item label="离职日期">
        <el-date-picker style="width: 160px" type="date" placeholder="选择日期时间" value-format="YYYY-MM-DD"
          v-model="form.batchTime"></el-date-picker>
      </el-form-item>
      <el-form-item label="是否雇员">
        <el-select v-model="form.isEmployee" filterable style="width: 160px; margin: 0">
          <el-option v-for="itemb in options" :key="itemb.value" :label="itemb.label" :value="itemb.value">
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="visible = false" size="small">取消</el-button>
        <el-button type="primary" @click="allotEdit()" size="small">确定</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import { saveEmployees } from "@/api/salary";

export default {
  name: "",
  data() {
    return {
      callList: [],
      dialogTableVisible: false,
      options: [
        {
          value: 1,
          label: "是",
        },
        {
          value: 2,
          label: "否",
        },
      ],
      visible: false,
      form: {
        batchTime: "",
        isEmployee: 2
      },
      sels:[]
    };
  },
  methods: {
    init(list) {
      this.callList = list;
      this.dialogTableVisible = true;
    },
    handleSelectionChange(e){
      this.sels = e
    },
    saveTimebatch() {
      let info = this.callList.lz.find(v => !v.departureDate)
      if (info) {
        this.$qzfMessage(`${info.name}的离职日期未填写`, 1)
        return
      }
      this.callList.lz.map(v => {
        v.status = 2
      })
      saveEmployees(this.callList.lz).then((res) => {
        if (res.data.msg == "success") {
          this.$message.success("修改成功");
          this.dialogTableVisible = false
        }
      });
    },
    allotEdit() {
      let arr = this.sels.length == 0 ? this.callList.lz : this.sels
      arr.map(v => {
        v.departureDate = this.form.batchTime
        v.isEmployee = this.form.isEmployee
      })
      this.$qzfMessage('修改成功')
      this.visible = false
    },
  },
};
</script>

<style scoped lang="scss">
.record {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;

  .record_left {
    font-size: 14px;
    color: #333;
    font-weight: 600;
  }
}

.record_left::before {
  content: "";
  background: var(--themeColor, #17a2b8);
  display: inline-block;
  width: 3px;
  height: 13px;
  position: relative;
  margin-right: 10px;
}
</style>
